<script setup lang="ts">
import { FlightSearchHistory } from '#types/flight';
import { HotelSuggestion } from '#types/hotel';

interface IProps {
  title: string;
  subtitle?: string;
  initialActiveTab?: 'flight' | 'hotel';
  initialSearchParams?: {
    flight?: FlightSearchHistory;
    hotel?: {
      destination?: Pick<HotelSuggestion, 'type' | 'id' | 'name' | 'city'>;
    };
  };
}

defineProps<IProps>();

const route = useRoute();
const getRouteBaseName = useRouteBaseName();

const showOrderListButton = computed(() => ['index', 'flight-slug'].includes(getRouteBaseName(route) ?? ''));
</script>

<template>
  <AppNavbar
    class="sticky top-0 z-30 w-full"
    alternate-on-scroll
  />

  <div
    :class="[
      'bg-gradient-to-b from-primary-alt to-primary-alt-light w-full flex justify-center',
      showOrderListButton ? 'h-[280px]' : 'h-[257px]',
    ]"
  >
    <div class="mt-30 container">
      <slot name="title">
        <h1 class="text-ultra text-white font-bold">
          {{ title }}
        </h1>
      </slot>

      <slot name="subtitle">
        <p class="text-medium text-white mt-10">{{ subtitle }}</p>
      </slot>
    </div>
  </div>

  <div class="absolute w-full top-[215px] z-20">
    <div class="container">
      <SharedHomeSearchTabs
        :initial-active-tab="initialActiveTab"
        :initial-search-params="initialSearchParams"
        :show-order-list-button="showOrderListButton"
      />
    </div>
  </div>
</template>
